// Imports
// ------------
import React, { useEffect, useRef, useState } from 'react';
import { Row, Column } from '@waffl';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ReactPlayer from 'react-player/file';
import Button from '@parts/Button';
import { observer } from 'mobx-react-lite';
import { menuState } from '@states';

import vid from '@images/cta.mp4';

// Styles
// ------------
import { Jacket, Background, Content, Heading, ButtonPos } from './styles';

// Component
// ------------
gsap.registerPlugin(ScrollTrigger);

const Hero404 = ({ title }) => {
    // NOTE • State
    const [ready, setReady] = useState(false);

    // NOTE • Refs
    const trigger = useRef();
    const target = useRef();
    const videoRef = useRef();

    // NOTE • Video Functions (Performance based)
    const play = () => {
        setReady(true);
    }

    const pause = () => {
        setReady(false);
    }

    // NOTE • Onload: scroll Animation
    useEffect(() => {
        const timer = setTimeout(() => {
            gsap.to(target.current, {
                scale: 1,
                ease: 'none',
                scrollTrigger: {
                    trigger: trigger.current,
                    start: '0% 100%',
                    end: '100% 100%',
                    scrub: 0.5,
                    markers: false,
                },
            });

            gsap.to(videoRef.current, {
                ease: 'none',
                scrollTrigger: {
                    trigger: trigger.current,
                    start: "0% 100%",
                    end: "100% 0%",
                    scrub: 0,
                    onEnter: play,
                    onEnterBack: play,
                    onLeaveBack: pause,
                    markers: false,
                }
            });
        }, 250);

        return () => {
            clearTimeout(timer);
        }
    }, []);

    return (
        <>
            <Background ref={target}>
                <ReactPlayer
                    ref={videoRef}
                    url={vid}
                    playing={ready}
                    loop
                    muted
                    playsinline
                    width={`100%`}
                    height={`100%`}
                    controls={false}
                />
            </Background>

            <Jacket ref={trigger} padBottom extraPad={menuState.heightSize}>
                <Row isExpanded style={{ height: "100%" }}>
                    <Column small={12} medium={10} pushMedium={1} mpad isMaxHeight>
                        <Content>
                            <Heading>It seems youve navigated to a broken page</Heading>
                            <ButtonPos>
                                <Button
                                    to="/"
                                    label="Take me home"
                                    icon="home"
                                />
                            </ButtonPos>
                        </Content>
                    </Column>
                </Row>
            </Jacket>
        </>
    );
}

export default observer(Hero404);