// Imports
// ------------
import React from 'react';
import Hero404 from '@parts/Hero404';
import Footer from '@parts/Footer';
import Seo from '@parts/Seo';



// Component
// ------------
const ErrorPage = ({ data }) => {

    return (
        <>
            <Seo />
            <Hero404 />
            <Footer />
        </>
    );
}

export default ErrorPage;